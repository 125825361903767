/* MOBILE */
$(document).on("click", "#search-icon", function (e) {
  e.preventDefault();

  if ($("#flex-searchbar").hasClass("active")) {
    $("#flex-searchbar").removeClass("active");
  } else {
    $("#flex-searchbar").addClass("active").find("input[type=text]").focus();
  }
});

$("#search_widget").on("click", ".close", function (e) {
  e.preventDefault();
  $("#flex-searchbar").removeClass("active");
});
