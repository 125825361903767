import "../../css/components/cookiechoice.scss";
import { gtagEvent } from "./googleDataLayer.js";

$(document).ready(function () {
  function _shouldDisplayConsent() {
    if (!document.cookie.match(new RegExp("displayCookieConsent=([^;]+)")) !== false) {
      $('#cookiesModal').modal('show');
      gtagEvent('consentSettings');
    }
  }

  function _saveUserPreference(value) {
    var expiryDate = new Date();
    if (value === 'yes') {
      gtagEvent('consentGranted');
    }
    else {
      gtagEvent('consentSettings');
    }
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    document.cookie = "displayCookieConsent=y; expires=" + expiryDate.toGMTString();
  }

  $(document).on("click", "#cookiechoiceout, #cookiechoicein", function (event) {
    event.preventDefault();
    _saveUserPreference($(this).data('value'));
    $('#cookiesModal').modal('hide');
  });

  _shouldDisplayConsent();
});
