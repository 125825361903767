import { isOfficialSite } from "./isofficialsite.js"
export function gtagEvent(event) {
  let dataproduct = "";

  //console.log('prestashop.cart', prestashop.cart);


  if (isOfficialSite() !== true) {
    console.log('gtagEvent ' + event + ' not fired : Not official site');
    return;
  }
  console.log('gtagEvent push : ' + event);


  switch (event) {
    case "consentSettings":
      gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "granted",
        personalization_storage: "granted",
        security_storage: "granted",
      });
      break;

    case "consentGranted":
      gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
        functionality_storage: "granted",
        personalization_storage: "granted",
        security_storage: "granted",
      });
      break;

    case "view_item":
      dataproduct = $('#product-details').data('product');
      const manufacturerName = $('#product-details').data('manufacturer');
      const view_item_data = {
        affiliation: "LBDBOX",
        currency: "EUR",
        value: dataproduct.price_amount,
        items: [
          {
            item_id: dataproduct.id.toString(),
            item_name: dataproduct.name,
            item_brand: manufacturerName,
            item_category: dataproduct.category_name
          }
        ]
      };
      gtag("event", event, view_item_data);
      console.log('view_item_data', event, view_item_data);
      console.log('dataproduct', event, dataproduct);
      break;


    case "add_to_cart":
      dataproduct = $('#product-details').data('product');
      const add_to_cart_data = {
        affiliation: "LBDBOX",
        currency: "EUR",
        value: dataproduct.price_amount,
        items: [
          {
            item_id: dataproduct.id,
            item_name: dataproduct.name
          }
        ]
      };
      gtag("event", event, add_to_cart_data);
      console.log('add_to_cart_data', event, add_to_cart_data);
      break;

    case "begin_checkout":
      const products = prestashop.cart.products;
      let items = [];
      let product;
      products.forEach(function (data) {
        product = {
          item_id: data.id_product,
          item_name: data.name,
          item_brand: data.manufacturer_name,
          quantity: data.quantity
        }
        items.push(product);
      });

      const begin_checkout_data = {
        affiliation: "LBDBOX",
        currency: "EUR",
        value: prestashop.cart.subtotals.products.amount,
        items: items
      };
      gtag("event", "begin_checkout", begin_checkout_data);
      console.log('begin_checkout_data', event, begin_checkout_data);
      break;

    case "purchase":
      const purchase_data = {
        transaction_id: googleMerchantTplVars.ORDER_ID,
        affiliation: "LBDBOX",
        value: googleMerchantTplVars.ORDER_TOTAL,
        tax: googleMerchantTplVars.ORDER_TAX,
        shipping: googleMerchantTplVars.ORDER_SHIPPING,
        currency: "EUR",
        items: googleMerchantTplVars['products']
      };
      gtag("event", "purchase", purchase_data);
      console.log('purchase_data', event, purchase_data);


      gtag('event', 'conversion', {
        'send_to': 'AW-997664703/N4dHCPv4_WoQv8_c2wM',
        'value': 1.0,
        'currency': 'EUR',
        'transaction_id': ''
      });


      /* Event snippet for ASUC - Achats conversion page
    
        gtag('event', 'conversion', {
            'send_to': 'AW-997664703/N4dHCPv4_WoQv8_c2wM',
            'value': 1.0,
            'currency': 'EUR',
            'transaction_id': ''
        });
      */

      break;
    case "gender":
      gtag('set', 'user_properties', {
        gender: '' + $("#gender").attr("data-gender") + '',
        testoryx: 'ok',
      });
      break;
  }
}



export function trackOrderConfirmation(dataOrder, dataProducts) {
  console.log(dataOrder);
  console.log(dataProducts);
  /*{literal}
         dataLayer.push({
         'event': 'purchase',
         'orderId': '{/literal}{$google_merchant_confiance.ORDER_ID}{literal}',
         'affiliation': 'Google online store',
         'orderTotal': {/literal}{$google_merchant_confiance.ORDER_TOTAL}{literal},
         'currencyCode': 'EUR',
         'tax': {/literal}{$google_merchant_confiance.ORDER_TAX}{literal},
         'shipping': {/literal}{$google_merchant_confiance.ORDER_SHIPPING}{literal},
         'items': [{/literal}
         {foreach $google_merchant_confiance.products AS $key => $product} 
         {
         'id': '{$product.ITEM_ID}',
         'name': '{$product.ITEM_NAME}',
         'quantity': {$product.ITEM_QUANTITY},
         'price': '{$product.ITEM_PRICE}'                         
         },
         {/foreach}{literal}  
         ]
         }); 
         {/literal}
         */
  pushProducts = {};
  dataProducts.forEach(function (product) {
    product = {
      id: product.id_product,
      name: product.name,
      price: "12.50",
      brand: "Converse",
      category: "Men/Clothing/T-Shirts",
      variant: "red",
      position: 0,
      quantity: 2,
    };
    pushProducts.push(product);
  });

  console.log(pushProducts);
  dataLayer.push({
    event: "EEtransaction",
    ecommerce: {
      purchase: {
        actionField: {
          orderId: dataOrder.ORDER_ID,
          affiliation: "Google Online Store",
          orderTotal: dataOrder.ORDER_TOTAL,
          revenue: dataOrder.ORDER_TOTAL,
          tax: "1.20",
          shipping: dataOrder.ORDER_SHIPPING,
        },
        products: [
          {
            id: "98adsh",
            name: "Converse T-Shirt",
            price: "12.50",
            brand: "Converse",
            category: "Men/Clothing/T-Shirts",
            variant: "red",
            position: 0,
            quantity: 2,
          },
          {
            id: "m3g45",
            name: "Boss Pants",
            price: "38.00",
            brand: "Boss",
            category: "Men/Clothing/Pants",
            variant: "blue",
            position: 1,
            quantity: 1,
          },
        ],
      },
    },
  });
}

export function trackPromoView(promoData) {
  console.log("trackPromoView");
  dataLayer.push({
    event: "eec.promotionView",
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: promoData.id,
            name: promoData.name,
            position: promoData.position,
          },
        ],
      },
    },
  });
}

export function trackPromoClick(promoData, link) {
  console.log("trackPromoClick");
  dataLayer.push({
    event: "EEpromotionClick",
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id: promoData.id,
            name: promoData.name,
            position: promoData.position,
          },
        ],
      },
    },
  });
  document.location.href = link;
}

export function trackPurchaseClick() {
  console.log("trackPurchaseClick");
  dataLayer.push({
    event: "trackPurchaseClick",
    currencyCode: "EUR",
  });

  //e.preventDefault();
}
