console.log("load search.js");
import "../../../css/modules/ps_searchbar.scss";
$("#search-input, #search-icon").on("click", function (e) {
  e.preventDefault();

  import(
    /* webpackChunkName: "mod_ps_searchbar" */ "../../components/modules/ps_searchbar/ps_searchbar.js"
  ).then((searchBarImport) => {
    searchBarImport.ps_searchbar();
    $(this).attr("placeholder", "").val("");
    $("#search-input").val("");
  });
});
